/* eslint-disable */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { baseUrlCourse, baseUrlCourse2 } from './BaseUrl';
const swal = require('sweetalert2')
const baseUrl = baseUrlCourse;
const baseUrl2 = baseUrlCourse2;
// const baseUrl = 'https://lnfcl.pythonanywhere.com/api/courses/';
// const baseUrl2 = 'https://lnfcl.pythonanywhere.com/api/students/';

const CourseContext = createContext();

const CourseProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const { authTokens, logoutUser, setUser, setAuthTokens, user, fetchWithAuth } = useContext(AuthContext)
  const [studentBulletins, setStudentBulletins] = useState([]);
  const [getBulletins, setGetBulletins] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Utilisez useNavigate pour la navigation
  const [loading, setLoading] = useState(true);

  const handleAuthError = (response) => {
    if (response.status === 401) {
      swal.fire({
        title: "Session Expired",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      logoutUser();
    }
  };
  const getCourses = async () => {
    try {
      const response = await fetchWithAuth(`${baseUrl}create-view/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchCoursesByLevel = async (level) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}level/${level}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCourseById = async (courseId) => {
    const response = await fetchWithAuth(`${baseUrl}detail-view/${courseId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    handleAuthError(response);
    const data = await response.json();
    // console.log(data);
    return data;
  }

  const getCoursByCode = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}get-course-by-code/`, {
      method: "POST",
      body: formData
    });

    handleAuthError(response);
    if (response.status === 200) {
      const data = await response.json();
      // console.log(data);
      return data;
    } else if (response.status === 404) {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log("there was a server issue");
    }
  };

  const getStudentByMatricule = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}get-student-by-matricule/`, {
      method: "POST",
      body: formData
    });
    handleAuthError(response);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log("there was a server issue");
    }
  };
  const deleteCourses = async (courseId) => {
    const response = await fetchWithAuth(`${baseUrl}detail-view/${courseId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    handleAuthError(response);
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  const updateCourses = async (courseId, formData) => {
    const response = await fetchWithAuth(`${baseUrl}detail-view/${courseId}/`, {
      method: "PUT",
      body: formData
    });
    handleAuthError(response);
    if (response.status === 200) {
      swal.fire({
        title: "Course Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
      navigate("/courses");
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  const createCourse = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}create-view/`, {
      method: "POST",
      body: formData
    });
    handleAuthError(response);

    if (response.status === 201) {
      window.location.reload();
      swal.fire({
        title: "Inscription Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else if (response.status === 400) {
      swal.fire({
        title: "Ce Cours Exist déjà pour ce niveau",
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getClasses = async () => {
    const response = await fetchWithAuth(`${baseUrl2}classes/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    handleAuthError(response);
    const data = await response.json();
    return data;
  };

  const addOrUpdateNote = async (noteData) => {
    const response = await fetchWithAuth(`${baseUrl}notes/`, {
      method: "POST",
      body: noteData,
    });
    handleAuthError(response);
    if (response.status === 201) {
      const data = await response.json();
      swal.fire({
        title: "Note ajoutée/mise à jour avec succès",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return data;
    } else {
      const errorData = await response.json();
      console.error(errorData);
      swal.fire({
        title: `Erreur : ${response.status}`,
        text: errorData.response,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      throw new Error('Erreur lors de l\'ajout/mise à jour de la note');

    }
  };

  const getAvailableTrimestres = async (studentId, courseId) => {
    const response = await fetchWithAuth(`${baseUrl}available-trimestres/`, {
      method: "POST",
      body: JSON.stringify({ student: studentId, course: courseId })
    });

    handleAuthError(response);
    if (response.ok) {
      const data = await response.json();
      return data.available_trimestres;
    } else {
      const errorData = await response.json();
      console.error('Erreur lors de la récupération des trimestres disponibles :', errorData);
      swal.fire({
        title: `Erreur : ${response.status}`,
        text: errorData.detail || 'Une erreur s\'est produite',
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      throw new Error('Erreur lors de la récupération des trimestres disponibles');
    }
  };

  const searchStudent = async (matricule) => {
    if (!matricule) {
      swal.fire({
        title: 'Veuillez entrer le code de l\'élève',
        icon: 'warning',
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append('matricule', matricule);
      const student = await getStudentByMatricule(formData);
      const body = JSON.stringify({ student_id: student.id });
      const response = await fetchWithAuth(`${baseUrl}student-bulletin/`, {
        method: "POST",
        body: body
      });

      handleAuthError(response);
      if (response.status === 404) {
        const data = await response.json();
        // console.log(data);
        setStudentData(data);
        swal.fire({
          title: 'Aucun élève trouvé',
          text: 'Code Incorrecte',
          icon: 'error',
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      else if (response.ok) {
        const data = await response.json();
        setStudentData(data);
        swal.fire({
          title: 'Élève trouvé avec succès',
          icon: 'success',
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        throw new Error('Étudiant non trouvé');
      }
    } catch (error) {
      console.error('Erreur lors de la recherche de l\'étudiant :', error);
      // swal.fire({
      //   title: 'Erreur lors de la recherche de l\'étudiant',
      //   text: error.message || 'Une erreur s\'est produite',
      //   icon: 'error',
      //   toast: true,
      //   timer: 3000,
      //   position: 'top-right',
      //   timerProgressBar: true,
      //   showConfirmButton: false,
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const StudentsWithGeneralAverage = async () => {
    try {
      const response = await fetchWithAuth(`${baseUrl}students-with-general-average/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStudentBulletins = async (niveau, classe) => {
    setIsLoading(true);
    // setError(null);

    try {
      const response = await fetchWithAuth(`${baseUrl}all-student-bulletin/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ niveau, classe }),
      });


      if (response.status === 200) {
        const data = await response.json();
        setStudentBulletins(data);
      }
      else if (response.status === 404) {
        const data = await response.json();
        setStudentBulletins(data);
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const GetStudentBulletins = async (matricule) => {
    setIsLoading(true);
    // setError(null);

    try {
      const response = await fetch(`${baseUrl}student-bulletin-get/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ matricule}),
      });


      if (response.status === 200) {
        const data = await response.json();
        setGetBulletins(data);
      }
      else if (response.status === 404) {
        const data = await response.json();
        setGetBulletins(data);
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const StudentsTotalNoteByID = async (student_id) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${student_id}/total-notes/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const StudentsArchivedTotalNote = async (year, matricule) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}archived-student-results/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ year, matricule }),
      });
      handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const StudentsTotalNote = async () => {
    try {
      const response = await fetchWithAuth(`${baseUrl}total-notes/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStudentData = async (studentIds) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}students-with-general-average-id/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: studentIds })
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const studentsData = await response.json();  // Attend la réponse et la convertit en JSON
      // console.log('Données des étudiants:', studentsData);
      return studentsData;  // Retourne les données des étudiants

      // Utilisez les données des étudiants comme nécessaire
    } catch (error) {
      console.error('Erreur lors de la récupération des données des étudiants:', error);
      // Gérez les erreurs ici, par exemple renvoyer null ou une structure de données vide
      return null;
    }
  };

  // const getStudentToken = async (matricule) => {
  //   const response = await fetch(`${baseUrl}student/token/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({ matricule })
  //   });

  //   handleAuthError(response);

  //   if (response.ok) {
  //     const data = await response.json();
  //     if (data && typeof data.token === 'string') {
  //       localStorage.setItem("authTokens", JSON.stringify(data));
  //       return data;
  //     } else {
  //       console.error("Token invalide dans la réponse:", data);
  //       swal.fire({
  //         title: "Échec de la récupération du jeton. Veuillez réessayer.",
  //         icon: "error",
  //         toast: true,
  //         timer: 3000,
  //         position: 'top-right',
  //         timerProgressBar: true,
  //         showConfirmButton: false,
  //       });
  //     }
  //   } else {
  //     console.error("Erreur du serveur:", response.status);
  //     swal.fire({
  //       title: "Erreur du serveur",
  //       icon: "error",
  //       toast: true,
  //       timer: 3000,
  //       position: 'top-right',
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //   }
  // };

  const getStudentToken = async (matricule, navigate) => {
    const response = await fetch(`${baseUrl}student/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ matricule })
    });

    handleAuthError(response);

    if (response.ok) {
      const data = await response.json();
      if (data && typeof data.token === 'string') {
        localStorage.setItem("authTokens", JSON.stringify(data));

        // Redirection après un token valide

        // navigate(`/student-result`);
        return data;
      } else {
        swal.fire({
          title: "Échec de la récupération du jeton de connexion",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } else {
      if (response.status === 403) {
        swal.fire({
          title: "Résultat non disponible",
          text: "Les résultats seront publiés avant la date de remise des bulletins.",
          icon: "warning",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      else if (response.status === 404) {
        swal.fire({
          title: "Étudiant non trouvé",
          text: "Aucun étudiant trouvé avec ce matricule.",
          icon: "warning",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        console.error("Erreur du serveur:", response.status);
        swal.fire({
          title: "Erreur du serveur",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const getStudentTotalNotesByToken = async () => {
    // Récupérer les tokens depuis localStorage
    const authTokens = JSON.parse(localStorage.getItem("authTokens")) || {};

    // Vérifiez si le token d'accès est présent
    if (!authTokens || !authTokens.token) {
      console.error("Token d'accès manquant");
      swal.fire({
        title: "Token d'accès manquant",
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    // Préparer l'en-tête d'authentification
    const authHeader = `Bearer ${authTokens.token}`;

    try {
      const response = await fetch(`${baseUrl}student/notes/`, {
        method: "GET",
        headers: {
          "Authorization": authHeader
        }
      });

      handleAuthError(response);
      if (response.status === 403) {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        navigate("/result");
        swal.fire({
          title: "Résultat non disponible",
          text: "Les résultats seront publiés avant la date de remise des bulletins.",
          icon: "warning",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      else if (response.ok) {
        const data = await response.json();
        return data;
      }
      else {
        console.error("Erreur du serveur:", response.status);
        swal.fire({
          title: "Erreur lors de la récupération des notes",
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Erreur de récupération:", error.message);
      swal.fire({
        title: "Une erreur s'est produite : " + error.message,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const handleInactivity = useCallback(() => {
    logoutUser();
  }, [logoutUser]);

  useEffect(() => {
    if (authTokens) {
      const token = authTokens.access;
      if (token && typeof token === 'string') {
        setUser(jwtDecode(token));
      } else {
        setUser(null);
      }
    }
    setLoading(false);
  }, [authTokens]);

  useEffect(() => {
    const events = ["mousemove", "keydown", "click"];
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleInactivity, 60 * 60 * 1000); // Déconnexion après 1 heure d'inactivité
    };

    events.forEach(event => window.addEventListener(event, resetTimeout));

    resetTimeout(); // Initialiser le timeout

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimeout));
      clearTimeout(timeout);
    };
  }, [handleInactivity]);


  const contextData = {
    authTokens, setAuthTokens,
    user, setUser,
    loading,
    createCourse,
    getCourses,
    getCourseById,
    updateCourses,
    deleteCourses,
    getClasses,
    getCoursByCode,
    getStudentByMatricule,
    addOrUpdateNote,
    getAvailableTrimestres,
    searchStudent,
    isLoading,
    studentData,
    StudentsWithGeneralAverage,
    fetchStudentData,
    fetchCoursesByLevel,
    StudentsTotalNoteByID,
    StudentsTotalNote,
    getStudentToken,
    getStudentTotalNotesByToken,
    fetchStudentBulletins,
    studentBulletins,
    StudentsArchivedTotalNote,
    GetStudentBulletins,
    getBulletins,
    error,
  };

  return (
    <CourseContext.Provider value={contextData}>
      {children}
    </CourseContext.Provider>
  );
};

export { CourseContext, CourseProvider };
