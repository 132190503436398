/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "./Utility";
import { ExportButton } from "./Data";
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { TeacherContext } from '../../context/TeacherContext';
import Loader from '../../Includes/loader/loader';
import { Url1 } from '../../context/BaseUrl';
const swal = require('sweetalert2')
// const imageToBase64 = require('image-to-base64');
// Data Inscription

const baseUrl2 = Url1;
 const DataTeachers = () => {
    const { getTeachers,deleteTeachers } = useContext(TeacherContext); // Récupérez la fonction getTeachers du contexte d'authentification
    const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
    const [records, setRecords] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingDirection, setLoadingDirection] = useState(false);
    const [loadingSurveillant, setLoadingSurveillant] = useState(false);
  

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getTeachers();
          setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
          setRecords(data);
          setLoading(false); 
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      };
      fetchData();
    }, [getTeachers]);

    if (loading) {
      return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
       const columnsTeacher = [
        {
          name: "ID",
          selector: (row) => row.id,
          sortable: true,
          maxWidth:'50px',
        },
        {
          name: "Code",
          selector: (row) => row.code,
          sortable: true,
        },
      {
        name: "Nom Complet",
        selector: (row) => (
          <div className="d-flex align-items-center">
            <img
              src={row.photo}
              alt="Photo"
              style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
            />
            {row.nom+" "+row.prenom}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row) => row.telephone,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="btn-group" role="group">
            <Link to={'/detail-teacher'} state={{teacher_id: row.id }} className="btn btn-success btn-sm me-1">
              <i className="fas fa-eye"></i>
            </Link>
            <Link to={'/detail-teacher'} state={{teacher_id: row.id }} className="btn btn-warning btn-sm me-1">
              <i className="fas fa-edit"></i>
            </Link>
            <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm ">
              <i className="fas fa-trash"></i>
            </button>
          </div>
        ),
      },
    ];
  

    function handleFilter(event) {
      const { value } = event.target;
      if (value === "") {
        setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
        return;
      }
      const newData = originalRecords.filter((row) => {
        const nomLower = row.nom.toLowerCase();
        const codeLower = row.code.toLowerCase();
        const prenomLower = row.prenom.toLowerCase();
        const searchLower = value.toLowerCase();
        return nomLower.includes(searchLower) || prenomLower.includes(searchLower)
        || codeLower.includes(searchLower);
      });
    
      setRecords(newData);
    }
    function handleDelete(id) {
      swal.fire({
        title: "Êtes-vous sûr(e) de vouloir supprimer cet élève?",
        text: "Une fois supprimé, vous ne pourrez pas récupérer cet élève!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteTeachers(id); // Assuming userId is defined
          swal.fire("Supprimé!", "Votre élève a été supprimé avec succès.", "success");
          await refreshData();
        }
      });
    }
    
    const refreshData = async () => {
      try {
        const data = await getTeachers();
        setOriginalRecords(data);
        setRecords(data);
      } catch (error) {
        console.error(error);
      }
    };
    
    const generatePDFDirection = async () => {
      setLoadingDirection(true); // Activer le loader
      try {
        const doc = new jsPDF();
        
        // Ajouter l'image en arrière-plan avec une qualité réduite
        doc.addImage(`/img/filig.png`, "PNG", 0, 0, 210, 297, undefined, 'FAST', 0.3);

        doc.addImage(`/img/header.png`, "PNG", 0, 0, 212, 48);
        // Titre
        doc.setFont("times", "bold");
        doc.setFontSize(16);
        doc.text("Liste des Professeurs - Direction", 105, 60, { align: "center" });

        // En-têtes de colonnes
        const headers = [["Nom", "Prénom", "NIF/CIN", "Téléphones", "Matière"]];
        
        // Données des professeurs avec style gras et couleur noire
        const data = records.map(teacher => [
          { content: teacher.nom, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: teacher.prenom, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: teacher.nif_cin, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: `${teacher.telephone}${teacher.telephone_add ? ' / ' + teacher.telephone_add : ''}`, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: teacher.matiere, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } }
        ]);

        // Créer le tableau avec des options optimisées
        doc.autoTable({
          startY: 70,
          head: headers,
          body: data,
          theme: 'grid',
          styles: {
            fontSize: 9,
            cellPadding: 1,
            overflow: 'linebreak',
            halign: 'left',
            minCellHeight: 8,
            font: "times",
            textColor: [0, 0, 0]
          },
          columnStyles: {
            0: { cellWidth: 35 },
            1: { cellWidth: 35 },
            2: { cellWidth: 35 },
            3: { cellWidth: 40 },
            4: { cellWidth: 37 }
          },
          headStyles: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontStyle: 'bold'
          },
          alternateRowStyles: {
            fillColor: [245, 245, 245]
          },
          margin: { top: 70, right: 10, bottom: 30, left: 10 }
        });

        // Pied de page optimisé
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(`Page ${i} sur ${pageCount}`, 105, doc.internal.pageSize.height - 10, { align: 'center' });
        }

        // Sauvegarder le PDF avec compression
        doc.save("liste_professeurs_direction.pdf", { compress: true });
      } catch (error) {
        console.error("Erreur lors de la génération du PDF:", error);
        swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la génération du PDF'
        });
      } finally {
        setLoadingDirection(false); // Désactiver le loader
      }
    };

    const generatePDFSurveillant = async () => {
      setLoadingSurveillant(true); // Activer le loader
      try {
        const doc = new jsPDF();
        
        // Ajouter l'image en arrière-plan avec une qualité réduite
        doc.addImage(`/img/filig.png`, "PNG", 0, 0, 210, 297, undefined, 'FAST', 0.3);

        doc.addImage(`/img/header.png`, "PNG", 0, 0, 212, 48);

        // Titre
        doc.setFont("times", "bold");
        doc.setFontSize(16);
        doc.text("Liste des Professeurs - Surveillance", 105, 60, { align: "center" });

        // En-têtes de colonnes
        const headers = [["Nom", "Prénom", "Téléphones", "Matière"]];
        
        // Données des professeurs avec style gras et couleur noire
        const data = records.map(teacher => [
          { content: teacher.nom, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: teacher.prenom, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: `${teacher.telephone}${teacher.telephone_add ? ' / ' + teacher.telephone_add : ''}`, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } },
          { content: teacher.matiere, styles: { fontStyle: 'bold', textColor: [0, 0, 0] } }
        ]);

        // Créer le tableau avec des options optimisées
        doc.autoTable({
          startY: 70,
          head: headers,
          body: data,
          theme: 'grid',
          styles: {
            fontSize: 9,
            cellPadding: 1,
            overflow: 'linebreak',
            halign: 'left',
            minCellHeight: 8,
            font: "times",
            textColor: [0, 0, 0]
          },
          columnStyles: {
            0: { cellWidth: 45 },
            1: { cellWidth: 45 },
            2: { cellWidth: 50 },
            3: { cellWidth: 40 }
          },
          headStyles: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontStyle: 'bold'
          },
          alternateRowStyles: {
            fillColor: [245, 245, 245]
          },
          margin: { top: 70, right: 10, bottom: 30, left: 10 }
        });

        // Pied de page optimisé
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(`Page ${i} sur ${pageCount}`, 105, doc.internal.pageSize.height - 10, { align: 'center' });
        }

        // Sauvegarder le PDF avec compression
        doc.save("liste_professeurs_surveillance.pdf", { compress: true });
      } catch (error) {
        console.error("Erreur lors de la génération du PDF:", error);
        swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la génération du PDF'
        });
      } finally {
        setLoadingSurveillant(false); // Désactiver le loader
      }
    };

    return (
      <>
        <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
          <div className="row">
            <div className="col-md-6 text-start d-flex align-items-center">
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm d-flex align-items-center"
                  onClick={generatePDFDirection}
                  disabled={loadingDirection || loadingSurveillant}
                >
                  {loadingDirection ? (
                    <>
                      <i className="fas fa-spinner fa-spin me-2"></i>
                      <span>Exportation...</span>
                    </>
                  ) : (
                    <>
                      <i className="fas fa-file-pdf me-2"></i>
                      <span>Liste Direction</span>
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm d-flex align-items-center"
                  onClick={generatePDFSurveillant}
                  disabled={loadingDirection || loadingSurveillant}
                >
                  {loadingSurveillant ? (
                    <>
                      <i className="fas fa-spinner fa-spin me-2"></i>
                      <span>Exportation...</span>
                    </>
                  ) : (
                    <>
                      <i className="fas fa-file-pdf me-2"></i>
                      <span>Liste Surveillance</span>
                    </>
                  )}
                </button>
              </div>
            </div>

            <div className="col-md-6 ">
              <input
                className="form-control mb-2"
                type="text"
                placeholder="Search"
                onChange={handleFilter}
              />
            </div>

            <div className="col-md-12 " >
              <DataTable
                columns={columnsTeacher}
                data={records}
                fixedHeader
                pagination
                customStyles={customStyles}
                paginationPerPage="10"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                subHeader
                noDataComponent="Aucune donnée trouvée"
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  export default DataTeachers;
