import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import AuthContext from "../../context/AuthContext";
import { TeacherContext } from "../../context/TeacherContext";
import Loader from "../../Includes/loader/loader";

function TeacherEdit() {
  const { getTeacherById, updateTeachers } = useContext(TeacherContext);
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { teacher_id } = location.state || {};
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageView, setProfileImageView] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userId = teacher_id;
    getTeacherById(userId).then((data) => {
      setRecords(data);
      setLoading1(false);
    });
  }, [getTeacherById, teacher_id]);

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProfileImage(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileImageView(imageUrl);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("created_by", user.user_id);
    if (profileImage) {
      formData.append("photo", profileImage);
    }
    setLoading(true);
    try {
      updateTeachers(records.id, formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Profil Professeur</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/teacher">Professeurs</Link>
                    </li>
                    <li className="breadcrumb-item active">Profil</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        Modifier les information du Professeur
                      </h3>

                      <div className="card-tools">
                        <Link
                          to="/teacher"
                          className="btn btn-primary btn-sm rounded-lg"
                        >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-3 m-0">
                      {loading1 ? (
                        <Loader />
                      ) : (
                        <form
                          onSubmit={handleSubmit}
                          className="forms-sample"
                          encType="multipart/form-data"
                        >
                            <div className="col-md-3">
                              <label htmlFor="profil">Photo</label>
                              <div className="form-group">
                                <img
                                  src={
                                    profileImageView ||
                                    (records && records.photo) ||
                                    ""
                                  }
                                  id="profil"
                                  alt="profil"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <input
                                  type="file"
                                  className="form-control form-control-sm mt-2"
                                  id="photo"
                                  name="photo"
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>

                          <div className="row">
                            
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                  type="text"
                                  value={records?.nom || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      nom: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="name"
                                  placeholder="Nom du Professeur"
                                  name="nom"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="prenom">Prénom</label>
                                <input
                                  type="text"
                                  value={records?.prenom || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      prenom: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="prenom"
                                  placeholder="Prénom du Professeur"
                                  name="prenom"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="date">Date de naissance</label>
                                <input
                                  type="date"
                                  value={records?.date_naissance || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      date_naissance: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="date"
                                  name="date_naissance"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="nif_cin">NIF/CIN</label>
                                <input
                                  type="text"
                                  value={records?.nif_cin || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      nif_cin: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="nif_cin"
                                  placeholder="NIF/CIN"
                                  name="nif_cin"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="ninu">NINU</label>
                                <input
                                  type="text"
                                  value={records?.ninu || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      ninu: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="ninu"
                                  placeholder="NINU"
                                  name="ninu"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="niveau_formation">
                                  Niveau de Formation
                                </label>
                                <input
                                  type="text"
                                  value={records?.niveau_formation || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      niveau_formation: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="niveau_formation"
                                  placeholder="Niveau de formation"
                                  name="niveau_formation"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="formation">Formation</label>
                                <input
                                  type="text"
                                  value={records?.formation || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      formation: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="formation"
                                  placeholder="Formation"
                                  name="formation"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="permis">Permis Provisoire</label>
                                <input
                                  type="text"
                                  value={records?.permis_provisoire || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      permis_provisoire: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="permis"
                                  placeholder="Permis provisoire"
                                  name="permis_provisoire"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  value={records?.email || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      email: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="email"
                                  placeholder="Email du Professeur"
                                  name="email"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="sexe">Sexe</label>
                                <select
                                  className="form-control form-control-sm"
                                  id="sexe"
                                  name="sexe"
                                  value={records?.sexe || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      sexe: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Sélectionner</option>
                                  <option value="M">Masculin</option>
                                  <option value="F">Féminin</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="telephone">
                                  Téléphone Principal
                                </label>
                                <input
                                  type="tel"
                                  value={records?.telephone || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      telephone: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="telephone"
                                  placeholder="Téléphone"
                                  name="telephone"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="telephone_add">
                                  Téléphone Secondaire
                                </label>
                                <input
                                  type="tel"
                                  value={records?.telephone_add || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      telephone_add: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="telephone_add"
                                  placeholder="Téléphone additionnel"
                                  name="telephone_add"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="matieres">
                                  Matières Enseignées
                                </label>
                                <input
                                  type="text"
                                  value={records?.matieres_enseigne || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      matieres_enseigne: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="matieres"
                                  placeholder="Matières enseignées"
                                  name="matieres_enseigne"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="niveau">Niveau Enseigné</label>
                                <input
                                  type="text"
                                  value={records?.niveau_enseigne || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      niveau_enseigne: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="niveau"
                                  placeholder="Niveau enseigné"
                                  name="niveau_enseigne"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="matiere">Matière Principale</label>
                                <input
                                  type="text"
                                  value={records?.matiere || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      matiere: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="matiere"
                                  placeholder="Matière"
                                  name="matiere"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="heures">
                                  Nombre d'Heures de Cours
                                </label>
                                <input
                                  type="text"
                                  value={records?.nbre_cours_heures || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      nbre_cours_heures: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="heures"
                                  placeholder="Nombre d'heures de cours"
                                  name="nbre_cours_heures"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="langues">Langues Maîtrisées</label>
                                <select
                                  className="form-control form-control-sm"
                                  id="langues"
                                  name="langues_maitrise"
                                  value={records?.langues_maitrise || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      langues_maitrise: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Sélectionner</option>
                                  <option value="Creole">Creole</option>
                                  <option value="Francais">Francais</option>
                                  <option value="Anglais">Anglais</option>
                                  <option value="Francais | Creole">
                                    Francais | Creole
                                  </option>
                                  <option value="Francais | Creole | Anglais">
                                    Francais | Creole | Anglais
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="autre_lanque">Autre Langue</label>
                                <input
                                  type="text"
                                  value={records?.autre_lanque || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      autre_lanque: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-sm"
                                  id="autre_lanque"
                                  placeholder="Autre langue"
                                  name="autre_lanque"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="modal-footer">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm me-2"></span>
                                  Enregistrement...
                                </>
                              ) : (
                                "Enregistrer"
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default TeacherEdit;
