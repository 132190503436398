import { useContext, useState } from "react";
import { TeacherContext } from "../../context/TeacherContext";
import AuthContext from "../../context/AuthContext";

function FormTeacher() {
  const { createTeacher } = useContext(TeacherContext);
  const [profileImage, setProfileImage] = useState(null);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const formData = new FormData();
    formData.append("nom", e.target.nom.value);
    formData.append("prenom", e.target.prenom.value);
    formData.append("date_naissance", e.target.date_naissance.value);
    formData.append("nif_cin", e.target.nif_cin.value);
    formData.append("ninu", e.target.ninu.value);
    formData.append("niveau_formation", e.target.niveau_formation.value);
    formData.append("formation", e.target.formation.value);
    formData.append("permis_provisoire", e.target.permis_provisoire.value);
    formData.append("email", e.target.email.value);
    formData.append("sexe", e.target.sexe.value);
    formData.append("telephone", e.target.telephone.value);
    formData.append("telephone_add", e.target.telephone_add.value);
    formData.append("matieres_enseigne", e.target.matieres_enseigne.value);
    formData.append("niveau_enseigne", e.target.niveau_enseigne.value);
    formData.append("matiere", e.target.matiere.value);
    formData.append("nbre_cours_heures", e.target.nbre_cours_heures.value);
    formData.append("langues_maitrise", e.target.langues_maitrise.value);
    formData.append("autre_lanque", e.target.autre_lanque.value);
    formData.append("created_by", user.user_id);

    if (e.target.photo.files[0]) {
      formData.append("photo", e.target.photo.files[0]);
    }

    try {
      await createTeacher(formData);
      e.target.reset();
      setProfileImage(null);
    } catch (error) {
      console.error("Erreur lors de la création du professeur:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <div className="modal-header">
          <h5 className="modal-title">Enregistrement d'un nouveau professeur</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="forms-sample" encType="multipart/form-data">
            <div className="row">
              {/* Section Photo */}
              {profileImage && (
                <div className="col-md-3">
                  <label htmlFor="profil">Photo</label>
                  <div className="form-group">
                    <img src={profileImage} id="profil" alt="Photo de profil" style={{ maxWidth: '80px', maxHeight: '80px', borderRadius: '50px' }} />
                  </div>
                </div>
              )}

              {/* Section Informations Personnelles */}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">Nom</label>
                  <input type="text" className="form-control form-control-sm" id="name" placeholder="Nom du Professeur" required name="nom" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="prenom">Prénom</label>
                  <input type="text" className="form-control form-control-sm" id="prenom" placeholder="Prénom du Professeur" required name="prenom" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="date">Date de naissance</label>
                  <input type="date" className="form-control form-control-sm" id="date" required name="date_naissance" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="nif_cin">NIF/CIN</label>
                  <input type="text" className="form-control form-control-sm" id="nif_cin" placeholder="NIF/CIN" required name="nif_cin" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="ninu">NINU</label>
                  <input type="text" className="form-control form-control-sm" id="ninu" placeholder="NINU" name="ninu" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="niveau_formation">Niveau de Formation</label>
                  <input type="text" className="form-control form-control-sm" id="niveau_formation" placeholder="Niveau de formation" required name="niveau_formation" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="formation">Formation</label>
                  <input type="text" className="form-control form-control-sm" id="formation" placeholder="Formation" required name="formation" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="permis">Permis Provisoire</label>
                  <input type="text" className="form-control form-control-sm" id="permis" placeholder="Permis provisoire" name="permis_provisoire" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control form-control-sm" id="email" placeholder="Email du Professeur" required name="email" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="sexe">Sexe</label>
                  <select className="form-control form-control-sm" id="sexe" required name="sexe">
                    <option value="">Sélectionner</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="telephone">Téléphone Principal</label>
                  <input type="tel" className="form-control form-control-sm" id="telephone" placeholder="Téléphone" name="telephone" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="telephone_add">Téléphone Secondaire</label>
                  <input type="tel" className="form-control form-control-sm" id="telephone_add" placeholder="Téléphone additionnel" name="telephone_add" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="matieres">Matières Enseignées</label>
                  <input type="text" className="form-control form-control-sm" id="matieres" placeholder="Matières enseignées" name="matieres_enseigne" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="niveau">Niveau Enseigné</label>
                  <input type="text" className="form-control form-control-sm" id="niveau" placeholder="Niveau enseigné" name="niveau_enseigne" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="matiere">Matière Principale</label>
                  <input type="text" className="form-control form-control-sm" id="matiere" placeholder="Matière" name="matiere" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="heures">Nombre d'Heures de Cours</label>
                  <input type="text" className="form-control form-control-sm" id="heures" placeholder="Nombre d'heures de cours" name="nbre_cours_heures" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="langues">Langues Maîtrisées</label>
                  <select className="form-control form-control-sm" id="langues" name="langues_maitrise">
                    <option value="">Sélectionner</option>
                    <option value="Creole">Creole</option>
                    <option value="Francais">Francais</option>
                    <option value="Anglais">Anglais</option>
                    <option value="Francais | Creole">Francais | Creole</option>
                    <option value="Francais | Creole | Anglais">Francais | Creole | Anglais</option>
                    <option value="Creole | Anglais">Creole | Anglais</option>
                    <option value="Francais | Anglais">Francais | Anglais</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="autre_langue">Autre Langue</label>
                  <input type="text" className="form-control form-control-sm" id="autre_langue" placeholder="Autre langue" name="autre_lanque" />
                </div>
              </div>

              {/* Section Photo Upload */}
              <div className="form-group col-md-3">
                <label htmlFor="photo">Photo</label>
                <div className="input-group input-group-sm">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="photo"
                      accept="image/*"
                      name="photo"
                      onChange={handleImageChange}
                    />
                    <label className="custom-file-label" htmlFor="photo">
                      {profileImage ? 'Image sélectionnée' : 'Sélectionner une image'}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text">Importer</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer avec bouton Submit */}
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Enregistrement en cours...
                  </>
                ) : (
                  <>
                    <i className="fas fa-save"></i> Enregistrer
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
    </>
  );
}

export default FormTeacher;